<template>
  <el-dialog title="补录操作页面" :visible.sync="dialogVisible" width="80%" :before-close="handleClose">
    <el-form v-model="addFundPlan" label-width="100px">
      <!--  水费  -->
      <el-row :gutter="21">
        <el-col :span="7">
          <el-form-item label="基金来源" width="120px">
            <el-input width="20px" v-model="addFundPlan.shopPrice"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  name: "CancelBorrow",
  data() {
    return {
      dialogVisible: false,
      addFundPlan: {}
    };
  },
   methods: {
    //打开弹窗
    showFund() {
      this.dialogVisible = true;
    },
        handleClose(done) {
      this.$confirm("确认关闭？")
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    }
};
</script>